import { useTranslation } from 'react-i18next';

import { SimpleSelect } from '../../common/widgets/SimpleSelect';

export const SelectWorkType = ({ workTypes, setWorkTypes }) => {
    const { t } = useTranslation();

    return (
        <SimpleSelect
            className="w-72"
            placeholder={t('HOSPITALITY_SELECT_TYPE_OF_WORK')}
            options={workTypes && !!workTypes.length ? workTypes.filter(type => !type.used).map(type => ({ value: type.id, label: type.use_title })) : []}
            value={[]}
            isClearable={false}
            callback={value => {
                setWorkTypes(prevWorkTypes =>
                    prevWorkTypes.map(type => {
                        if (type.id === value.value) {
                            return { ...type, used: 1 };
                        }

                        return type;
                    })
                );
            }}
        />
    );
};
