import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { inputOnlyInteger, listenForOutsideClick } from '../../../api/functions';

export const RangeSelect = ({ callback, value }) => {
    const { t } = useTranslation();

    const [values, setValues] = useState({ from: '', to: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [listening, setListening] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        if (!isTouched && isOpen) {
            setIsTouched(true);
        }
        if (!isOpen && isTouched) {
            callback(values);
        }
    }, [isOpen, isTouched]);

    useEffect(() => {
        if (!value.from && !value.to) {
            setValues(value);
        }
    }, [value]);

    useEffect(listenForOutsideClick(listening, setListening, ref, () => setIsOpen(false)));

    return (
        <div className="relative w-44" ref={ref}>
            <button
                className={`w-full h-10 flex flex-row items-center py-2 border rounded-1 text-gray-50 bg-white ${
                    isOpen ? 'border-blue ring-1 ring-blue' : 'border-gray-70 hover:border-gray-30'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="h-full px-2 text-sm box-border select-none line-clamp-1 text-left">
                    {t('HOSPITALITY_NUMBER')} {t('HOSPITALITY_FROM')} {values.from && <b>{values.from}</b>} - {t('HOSPITALITY_TO')}{' '}
                    {values.to && <b>{values.to}</b>}
                </div>

                <div className="flex items-center justify-center px-2 ml-auto shrink-0 border-l border-l-gray-70">
                    <svg height="20" width="20" viewBox="0 0 20 20" className="fill-gray-50">
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                    </svg>
                </div>
            </button>

            <div className={`absolute mt-2 w-full flex flex-row gap-2 p-2 bg-white rounded shadow-3xl border border-gray-70 ${!isOpen && 'hidden'}`}>
                <input
                    type="text"
                    className="w-full h-8 border-gray-70 hover:border-gray-30 text-sm px-2"
                    placeholder={t('HOSPITALITY_FROM')}
                    value={values.from}
                    onChange={e => setValues({ ...values, from: inputOnlyInteger(e.target.value) })}
                />
                <input
                    type="text"
                    className="w-full h-8 border-gray-70 hover:border-gray-30 text-sm px-2"
                    placeholder={t('HOSPITALITY_TO')}
                    value={values.to}
                    onChange={e => setValues({ ...values, to: inputOnlyInteger(e.target.value) })}
                />
            </div>
        </div>
    );
};
