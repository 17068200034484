import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

export const EmployeeSelect = ({ options, employee, callback }) => {
    return (
        <SimpleSelect
            closeMenuOnSelect={true}
            isSearchable={true}
            isClearable={false}
            options={options}
            placeholder="Выберите сотрудника"
            value={employee.employee_data}
            callback={val => callback(val, employee.employeeId)}
            className="w-80 text-sm "
        />
    );
};
