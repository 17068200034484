import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { bmsAPI } from '../../../api/modules/bmsAPI';
import { store } from '../../store.ts';

export const bmsSliceEventLog = createSlice({
    name: 'bmsEventLog',

    initialState: {
        bms_detail_alerts: [],
        bms_detail_history: [],
        bmsEventLogList: null,
        bmsEventLogListFetch: false,
        bmsEventLogFiltersList: null,
        bms_detail_alerts_fetching: false,
        bms_detail_history_fetching: false,
        bmsEventLogFilters: {
            sort: '-date',
            page: 1,
            page_size: 10,
        },
    },

    reducers: {
        clear_bms_doc_detail: state => {
            state.bms_detail_history = [];
            state.bms_detail_alerts = [];
        },
        set_bms_detail_alerts: (state, action) => {
            state.bms_detail_alerts = action.payload;
        },
        set_bms_detail_alerts_fetching: (state, action) => {
            state.bms_detail_alerts_fetching = action.payload;
        },
        set_bms_detail_history: (state, action) => {
            state.bms_detail_history = action.payload;
        },
        set_bms_detail_history_fetching: (state, action) => {
            state.bms_detail_history_fetching = action.payload;
        },
        setBMSEventLogList: (state, action) => {
            state.bmsEventLogList = action.payload;
        },
        setBMSEventLogListFetch: (state, action) => {
            state.bmsEventLogListFetch = action.payload;
        },
        setBMSOperator: (state, action) => {
            state.bmsEventLogList.tBody = state.bmsEventLogList.tBody.map(event =>
                event.id === action.payload.id ? { ...event, operator: action.payload.fullName } : event
            );
        },
        setBMSEventLogFiltersList: (state, action) => {
            state.bmsEventLogFiltersList = action.payload;
        },
        setBMSEventLogFilters: (state, action) => {
            state.bmsEventLogFilters =
                action.payload.type !== 'page'
                    ? { ...state.bmsEventLogFilters, [action.payload.type]: action.payload.value, page: 1 }
                    : { ...state.bmsEventLogFilters, [action.payload.type]: action.payload.value };
        },
    },
});

export const {
    setBMSOperator,
    setBMSEventLogList,
    clear_bms_doc_detail,
    setBMSEventLogFilters,
    set_bms_detail_alerts,
    set_bms_detail_history,
    setBMSEventLogListFetch,
    setBMSEventLogFiltersList,
    set_bms_detail_alerts_fetching,
    set_bms_detail_history_fetching,
} = bmsSliceEventLog.actions;

// Получение системных сообщений об ошибках
export const getBMSDocAlerts =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSAlerts.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_detail_alerts(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocAlerts \n ${e}`, toastOptions);
        }
    };

// Получение трендов (исторических данных)
export const getBMSDocHistory =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSHistory.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_detail_history(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSDocHistory \n ${e}`, toastOptions);
        }
    };

// получение данных журнала событий
export const getBMSEventLogList =
    ({ data }) =>
    async dispatch => {
        dispatch(setBMSEventLogListFetch(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSEventLog.fetch(username, auth_key_calendar, data);
            if (response.status === 200) {
                const result = await response.json();

                if (result.code === 0) {
                    dispatch(setBMSEventLogList(result.data));
                }
            }
        } catch (e) {
            toast.error(`Method getBmsEventLogList \n ${e}`, toastOptions);
        } finally {
            dispatch(setBMSEventLogListFetch(false));
        }
    };

// ПИНГ получения данных журнала событий
export const getBMSEventLogListTimeout =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getBMSEventLog.fetch(username, auth_key_calendar, data);
            if (response.status === 200) {
                const result = await response.json();

                if (result.code === 0) {
                    dispatch(setBMSEventLogList(result.data));
                }
            }
        } catch (e) {
            toast.error(`Method getBmsEventLogList \n ${e}`, toastOptions);
        }
    };

// получение списка фильтров журнала событий
export const getBMSJournalFiltersList = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    try {
        const response = await bmsAPI.getBMSJournalFilters.fetch(username, auth_key_calendar);
        if (response.status === 200) {
            const result = await response.json();
            if (result.code === 0) {
                dispatch(setBMSEventLogFiltersList(result.data));
            }
        }
    } catch (e) {
        toast.error(`Method getBMSJournalFiltersList \n ${e}`, toastOptions);
    }
};

// подтверждение события в журнале событий
export const confirmBMSLogEven = id => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const fullName = store.getState().login.fullName;

    try {
        const response = await bmsAPI.confirmBMSLogEven.fetch(username, auth_key_calendar, id);
        if (response.status === 200) {
            const result = await response.json();
            if (result.code === 0) {
                dispatch(setBMSOperator({ id, fullName }));
            }
        }
    } catch (e) {
        toast.error(`Method confirmBMSLogEven \n ${e}`, toastOptions);
    }
};

export const bmsEventLogListState = state => state.bmsEventLog.bmsEventLogList;
export const bmsDetailAlertsState = state => state.bmsEventLog.bms_detail_alerts;
export const bmsDetailHistoryState = state => state.bmsEventLog.bms_detail_history;
export const bmsEventLogFiltersState = state => state.bmsEventLog.bmsEventLogFilters;
export const bmsEventLogListFetchState = state => state.bmsEventLog.bmsEventLogListFetch;
export const bmsEventLogFiltersListState = state => state.bmsEventLog.bmsEventLogFiltersList;
export const bmsDetailAlertsFetchingState = state => state.bmsEventLog.bms_detail_alerts_fetching;
export const bmsDetailHistoryFetchingState = state => state.bmsEventLog.bms_detail_history_fetching;

export default bmsSliceEventLog.reducer;
