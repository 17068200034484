import { useTranslation } from 'react-i18next';

export const SettingsButton = ({ setSettingsPaneIsOpen }) => {
    const { t } = useTranslation();

    return (
        <button
            onClick={() => setSettingsPaneIsOpen(true)}
            className="h-full flex flex-row items-center gap-2 text-sm text-gray-50 hover:text-gray-20 pl-2 pr-5 border-r border-r-gray-70 box-border"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M9.70825 16.7915V12.2083H10.7915V13.9583H16.7915V15.0415H10.7915V16.7915H9.70825ZM3.20825 15.0415V13.9583H7.79154V15.0415H3.20825ZM6.20825 12.2915V10.5415H3.20825V9.45825H6.20825V7.70825H7.29154V12.2915H6.20825ZM9.20825 10.5415V9.45825H16.7915V10.5415H9.20825ZM12.2083 7.79154V3.20825H13.2915V4.95825H16.7915V6.04154H13.2915V7.79154H12.2083ZM3.20825 6.04154V4.95825H10.7915V6.04154H3.20825Z" />
            </svg>
            {t('HOSPITALITY_SETTINGS')}
        </button>
    );
};
