import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { useCheckRole } from '../../../hooks/useCheckRole';

import { Loader } from '../../common/Loader';
import { PopupActionConfirmation } from '../mainPage/PopupActionConfirmation';
import { CopiedDocument } from './copiedDocument/CopiedDocument';

import {
    getBMSDocList,
    bms_doc_list_fetching as bms_doc_list_fetching_state,
    bms_doc_list as bms_doc_list_state,
    removeBMSDoc,
    setSelectedDocumentForCopy,
    selectedDocumentForCopyState,
} from '../../../redux/slices/bms/bmsSliceDocuments';

export const DocumentList = () => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();

    const bms_doc_list_fetching = useSelector(bms_doc_list_fetching_state);
    const bms_doc_list = useSelector(bms_doc_list_state);
    const selectedDocumentForCopy = useSelector(selectedDocumentForCopyState);

    const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const isDocumentDelete = useCheckRole('bms', 'bms.document.del');

    useEffect(() => {
        if (!bms_doc_list.length) {
            dispatch(getBMSDocList({ object_id: venueId, direction_id: systemId }));
        }
    }, [dispatch, venueId]);

    const handlerSystemRemoveClick = id => {
        dispatch(removeBMSDoc({ id }));
        setIsOpenConfirmationPopup(false);
    };

    const handlerCopyDocument = documentId => {
        dispatch(setSelectedDocumentForCopy(documentId));
    };

    if (bms_doc_list_fetching) {
        return (
            <div className="flex justify-center items-center w-52 h-28">
                <Loader />
            </div>
        );
    }
    return (
        <>
            {bms_doc_list.length ? (
                bms_doc_list.map(doc => (
                    <div className="relative" key={`bms${doc.id}`}>
                        <Link
                            to={`/bms/venues/${venueId}/system/${systemId}/documents/${doc.id}`}
                            className="flex flex-col justify-center items-center overflow-hidden transition-colors w-52 h-28 p-2 border-gray-10 hover:border-blue border-2 rounded-md bg-white hover:bg-blue-10"
                        >
                            {doc.title && <div className="uppercase text-sm font-bold text-center line-clamp-2">{doc.title}</div>}
                            {doc.description && <div className="text-xs mt-0.5 text-center line-clamp-2">{doc.description}</div>}
                        </Link>
                        {isDocumentDelete && (
                            <button
                                type="button"
                                className="absolute top-2 right-2 cursor-pointer transition-colors"
                                onClick={() => {
                                    setIsOpenConfirmationPopup(true);
                                    setSelectedDocument(doc);
                                }}
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" className="hover:fill-red-500 fill-current">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.75 6C13.1642 6 13.5 6.33579 13.5 6.75V14.25C13.5 15.4926 12.4926 16.5 11.25 16.5H6.75C5.50736 16.5 4.5 15.4926 4.5 14.25V6.75C4.5 6.33579 4.83579 6 5.25 6H12.75ZM12 7.5H6V14.25C6 14.6642 6.33579 15 6.75 15H11.25C11.6642 15 12 14.6642 12 14.25V7.5ZM6.75 2.25C6.75 1.83579 7.08579 1.5 7.5 1.5H10.5C10.9142 1.5 11.25 1.83579 11.25 2.25V3H14.25C14.6642 3 15 3.33579 15 3.75C15 4.16421 14.6642 4.5 14.25 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75C3 3.33579 3.33579 3 3.75 3H6.75V2.25Z"
                                    />
                                </svg>
                            </button>
                        )}
                        <button className="absolute top-2.5 right-7 cursor-pointer transition-colors" type="button" onClick={() => handlerCopyDocument(doc.id)}>
                            <svg width="16" height="16" viewBox="0 0 699.428 699.428" className="hover:fill-green-500 fill-current">
                                <path d="M502.714,0c-2.71,0-262.286,0-262.286,0C194.178,0,153,42.425,153,87.429l-25.267,0.59 c-46.228,0-84.019,41.834-84.019,86.838V612c0,45.004,41.179,87.428,87.429,87.428H459c46.249,0,87.428-42.424,87.428-87.428 h21.857c46.25,0,87.429-42.424,87.429-87.428v-349.19L502.714,0z M459,655.715H131.143c-22.95,0-43.714-21.441-43.714-43.715 V174.857c0-22.272,18.688-42.993,41.638-42.993L153,131.143v393.429C153,569.576,194.178,612,240.428,612h262.286 C502.714,634.273,481.949,655.715,459,655.715z M612,524.572c0,22.271-20.765,43.713-43.715,43.713H240.428 c-22.95,0-43.714-21.441-43.714-43.713V87.429c0-22.272,20.764-43.714,43.714-43.714H459c-0.351,50.337,0,87.975,0,87.975 c0,45.419,40.872,86.882,87.428,86.882c0,0,23.213,0,65.572,0V524.572z M546.428,174.857c-23.277,0-43.714-42.293-43.714-64.981 c0,0,0-22.994,0-65.484v-0.044L612,174.857H546.428z M502.714,306.394H306c-12.065,0-21.857,9.77-21.857,21.835 c0,12.065,9.792,21.835,21.857,21.835h196.714c12.065,0,21.857-9.771,21.857-21.835 C524.571,316.164,514.779,306.394,502.714,306.394z M502.714,415.57H306c-12.065,0-21.857,9.77-21.857,21.834 c0,12.066,9.792,21.836,21.857,21.836h196.714c12.065,0,21.857-9.77,21.857-21.836C524.571,425.34,514.779,415.57,502.714,415.57 z" />
                            </svg>
                        </button>
                    </div>
                ))
            ) : (
                <div className="flex flex-col justify-center items-center overflow-hidden w-64 h-28 p-2">Нет созданных систем</div>
            )}

            {selectedDocumentForCopy && <CopiedDocument />}

            {isOpenConfirmationPopup && (
                <PopupActionConfirmation
                    isOpen={isOpenConfirmationPopup}
                    setIsOpen={setIsOpenConfirmationPopup}
                    callback={handlerSystemRemoveClick}
                    element={selectedDocument}
                    title="Вы действительно хотите удалить систему"
                />
            )}
        </>
    );
};
