import { useTranslation } from 'react-i18next';

import { ButtonRectangular } from '../btn/ButtonRectangular';

export const SlidingPaneFooter = ({ closePaneHandler, callback }) => {
    const { t } = useTranslation();

    return (
        <div className="h-16 w-full fixed bottom-0 left-0 bg-white border-t border-t-gray-10">
            <div className="w-full h-full px-4 flex flex-row items-center">
                <ButtonRectangular className="bg-blue border-blue hover:bg-blue-500" onClick={callback}>
                    {t('HOSPITALITY_APPLY')}
                </ButtonRectangular>
                <ButtonRectangular onClick={closePaneHandler} className="bg-transparent border-transparent hover:bg-transparent text-gray hover:text-gray-20">
                    {t('HOSPITALITY_CANCEL')}
                </ButtonRectangular>
            </div>
        </div>
    );
};
