import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toastOptions } from '../../../api/functions';
import {
    actApplyAll,
    apply_tasks_fetching as apply_tasks_fetching_state,
    auto_mode as auto_mode_state,
    tasks as tasks_state,
} from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const ApplyTasksButton = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const tasks = useSelector(tasks_state);
    const apply_tasks_fetching = useSelector(apply_tasks_fetching_state);
    const auto_mode = useSelector(auto_mode_state);

    const applyAllHandler = async () => {
        const result = await dispatch(actApplyAll());

        if (result) {
            toast.success(t('HOSPITALITY_SAVE_OK'), toastOptions);
        } else {
            toast.error(t('HOSPITALITY_SAVE_ERROR'), toastOptions);
        }
    };

    return (
        <ButtonRectangular
            disabled={!tasks.find(task => !task.is_applied && task.status_id !== 8) || auto_mode}
            loading={apply_tasks_fetching}
            onClick={applyAllHandler}
            className={i18n.language === 'ru' ? 'w-40' : 'w-28'}
        >
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M7.95825 15.0001L3.20825 10.2501L4.39575 9.06258L7.95825 12.6251L15.6041 4.97925L16.7916 6.16675L7.95825 15.0001Z" />
            </svg>
            {t('HOSPITALITY_APPLY')}
        </ButtonRectangular>
    );
};
