import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toastOptions } from '../../../api/functions';
import { SimpleSelect } from '../../common/widgets/SimpleSelect';
import {
    getRooms,
    getTypicalTasksList,
    rooms as rooms_state,
    workers as workers_state,
    task_list as task_list_state,
    createTask,
} from '../../../redux/slices/hospitalitySlice';

import { SlidingPaneHeader } from '../../common/slidingPane/SlidingPaneHeader';
import { SlidingPaneFooter } from '../../common/slidingPane/SlidingPaneFooter';
import { RoomStatus } from './RoomStatus';

const initialState = {
    room: null,
    task: null,
    worker: null,
    amount: '',
};

export const TaskPane = ({ isOpen, setIsOpen }) => {
    const [taskFields, setTaskFields] = useState(initialState);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const rooms = useSelector(rooms_state);
    const workers = useSelector(workers_state);
    const task_list = useSelector(task_list_state);

    useEffect(() => {
        if (isOpen) {
            dispatch(getRooms());
            dispatch(getTypicalTasksList());
        }
    }, [isOpen]);

    const closePaneHandler = () => {
        setIsOpen(false);
        setTaskFields(initialState);
    };

    const createTaskHandler = () => {
        if (taskFields.room && taskFields.task) {
            const data = {
                office_id: taskFields.room.value,
                user_id: taskFields.worker && taskFields.worker.value ? taskFields.worker.value : null,
                typical_id: taskFields.task.value,
                typical_amount: taskFields.amount,
            };

            const status = dispatch(createTask({ data }));

            if (status) {
                toast.success(t('HOSPITALITY_CREATED_TASK'), toastOptions);
                setIsOpen(false);
                setTaskFields(initialState);
            } else {
                toast.error(t('HOSPITALITY_CREATED_TASK_ERROR'), toastOptions);
            }
        } else {
            toast.error(t('HOSPITALITY_CREATED_TASK_ERROR_1'), toastOptions);
        }
    };

    const rooms_options = rooms.map(room => ({ label: room.room_number, value: room.room_id }));
    const task_options = task_list.map(task => ({ label: t(task.code), value: task.id, numeric: task.is_numerical }));
    const workers_options = workers.filter(worker => worker.state === 1).map(worker => ({ label: worker.name, value: worker.id }));

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100  h-full"
            width="25rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_CREATE_TASK')} />

            {/* room number */}
            <div className="mb-6">
                <div className="flex flex-row justify-between w-80 mb-2">
                    <p className="text-sm font-medium">{t('HOSPITALITY_ROOM_NUMBER')}</p>
                    <RoomStatus roomName={taskFields.room ? taskFields.room.label : ''} />
                </div>

                <SimpleSelect
                    isSearchable={true}
                    options={rooms_options}
                    placeholder={t('HOSPITALITY_SELECT_ROOM_NUMBER')}
                    value={taskFields.room}
                    callback={val => setTaskFields(taskFields => ({ ...taskFields, room: val ? { label: val.label, value: val.value } : null }))}
                    className="w-80"
                />
            </div>

            {/* task name */}
            <div className="mb-6">
                <p className="text-sm font-medium mb-2">{t('HOSPITALITY_REQUEST')}</p>

                <SimpleSelect
                    isSearchable={true}
                    options={task_options}
                    placeholder={t('HOSPITALITY_SELECT_REQUEST')}
                    value={taskFields.task}
                    callback={val =>
                        setTaskFields(taskFields => ({ ...taskFields, task: val ? { label: val.label, value: val.value, numeric: val.numeric } : null }))
                    }
                    className="w-80"
                />
            </div>

            {/* task amount */}
            {taskFields.task && taskFields.task.numeric && (
                <div className="mb-6">
                    <p className="text-sm font-medium mb-2">{t('HOSPITALITY_QUANTITY')}</p>
                    <input
                        type="number"
                        value={taskFields.amount}
                        placeholder={t('HOSPITALITY_SELECT_QUANTITY')}
                        className="w-80 h-9 p-2 border-gray-10 border text-sm"
                        onChange={e => setTaskFields(taskFields => ({ ...taskFields, amount: e.target.value }))}
                    />
                </div>
            )}

            {/* worker name */}
            <div>
                <p className="text-sm font-medium mb-2">{t('HOSPITALITY_EXECUTOR')}</p>

                <SimpleSelect
                    isSearchable={true}
                    options={workers_options}
                    placeholder={t('HOSPITALITY_CHOOSE_EXECUTOR')}
                    value={taskFields.worker}
                    callback={val => setTaskFields(taskFields => ({ ...taskFields, worker: val ? { label: val.label, value: val.value } : null }))}
                    className="w-80"
                />
            </div>

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={createTaskHandler} />
        </SlidingPane>
    );
};
