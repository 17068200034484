import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { settingsState } from '../../../redux/slices/hospitalitySlice';

import { PopupFixed } from '../../common/popup/PopupFixed';
import { Task } from '../task/Task';

const statusIdArray = [2, 3, 8];

export const InformationPopup = ({ isOpen, setIsOpen, buttonRef }) => {
    const { t } = useTranslation();
    const settings = useSelector(settingsState);

    return (
        <PopupFixed isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
            <div className="mb-4 text-black font-medium">{t('HOSPITALITY_TYPES_OF_CLEANING')}</div>
            <div className="flex flex-col gap-4 text-black text-sm mb-8 max-h-64 overflow-y-auto">
                {settings && settings.hasOwnProperty('pms_task_types') && settings.pms_task_types.filter(type => type.used).length ? (
                    settings.pms_task_types
                        .filter(type => type.used)
                        .map((type, index) => (
                            <div className="flex flex-row items-center gap-6" key={type.id}>
                                <Task status={statusIdArray[index % 3]} type={type.id}>
                                    А{index + 1000}
                                </Task>
                                <div>{type.use_title}</div>
                            </div>
                        ))
                ) : (
                    <p>{t('HOSPITALITY_NO_TYPES_OF_CLEANING')}</p>
                )}
            </div>

            <div className="mb-4 text-black font-medium">{t('HOSPITALITY_REQUESTS_STATUS')}</div>
            <div className="flex flex-col gap-4 text-black">
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-new"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_NEW')}</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-at-work"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_ON_PROGRESS')}</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="w-2.5 h-2.5 rounded-full bg-status-completed"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_FINISH')}</div>
                </div>
            </div>
        </PopupFixed>
    );
};
