import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './slices/loginSlice';
import calendarReducer from './slices/calendarSlice';
import adminSchedulerReducer from './slices/adminSchedulerSlice';
import applicationsReducer from './slices/applicationsSlice';
import qualificationReducer from './slices/qualificationSlice';
// import bmsReducer from './slices/bms/bmsSlice';
import hospitalityReducer from './slices/hospitalitySlice';
import bmsVenuesReducer from './slices/bms/bmsSliceVenues';
import bmsSystemReducer from './slices/bms/bmsSliceSystems';
import bmsServersReducer from './slices/bms/bmsSliceServers';
import bmsDocumentsReducer from './slices/bms/bmsSliceDocuments';
import bmsEventLogReducer from './slices/bms/bmsSliceEventLog';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        calendar: calendarReducer,
        admin_scheduler: adminSchedulerReducer,
        applications: applicationsReducer,
        qualification: qualificationReducer,
        // bms: bmsReducer,
        bmsServers: bmsServersReducer,
        bmsVenues: bmsVenuesReducer,
        bmsSystems: bmsSystemReducer,
        bmsDocuments: bmsDocumentsReducer,
        bmsEventLog: bmsEventLogReducer,
        hospitality: hospitalityReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppGetState = typeof store.getState;
