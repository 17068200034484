import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    actResetForUser,
    auto_mode as auto_mode_state,
    setWorkersAtWork,
    tasks as tasks_state,
    workers as workers_state,
} from '../../../redux/slices/hospitalitySlice';
import { Droppable } from '../../common/dnd/Droppable';
import { CleanerMenuPopup } from './CleanerMenuPopup';
import { CleanerTasksList } from './CleanerTasksList';
import { CleanerMenuButton } from './CleanerMenuButton';

export const Cleaner = ({ id, name, isOpenMenu, setIsOpenMenu, activeCleanerMenuRef }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const workers = useSelector(workers_state);
    const tasks = useSelector(tasks_state);
    const auto_mode = useSelector(auto_mode_state);

    const cleanerTasks = useMemo(() => tasks.filter(task => task.user_id === id && task.is_filtered), [tasks, id]);

    const [isOpenTasks, setIsOpenTasks] = useState(false);

    const deleteWorkerHandler = id => {
        const user_ids = workers.filter(worker => worker.state && worker.id !== id).map(worker => worker.id);

        dispatch(setWorkersAtWork({ user_ids: JSON.stringify(user_ids) }));
        setIsOpenMenu(null);
    };

    const deleteTasksHandler = id => {
        dispatch(actResetForUser({ user_id: id }));
        setIsOpenMenu(null);
    };

    return (
        <div className={`w-56 bg-white rounded relative box-border ${isOpenTasks ? 'shadow-3xl h-fit' : 'h-20'} ${isOpenMenu === id ? 'z-20' : 'z-10'}`}>
            <Droppable id={id}>
                <div className="w-full pl-3 flex flex-col">
                    <div className="flex flex-row items-center w-full relative border-b border-b-gray-10 h-10 pr-3">
                        <div className="text-sm font-medium text-gray-20 line-clamp-1">{name}</div>

                        {!auto_mode ? (
                            <>
                                <CleanerMenuButton
                                    onClick={() => setIsOpenMenu(isOpenMenu !== id ? id : null)}
                                    buttonRef={isOpenMenu === id ? activeCleanerMenuRef : null}
                                />

                                <CleanerMenuPopup isOpen={isOpenMenu === id}>
                                    <button className="text-left px-3 py-2 hover:text-blue" onClick={() => deleteWorkerHandler(id)}>
                                        {t('HOSPITALITY_DELETE_USER')}
                                    </button>
                                    <button className="text-left px-3 py-2 hover:text-blue" onClick={() => deleteTasksHandler(id)}>
                                        {t('HOSPITALITY_CLEAN_REQUESTS')}
                                    </button>
                                </CleanerMenuPopup>
                            </>
                        ) : null}
                    </div>
                    <button className="w-full h-10 flex flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="text-sm">
                            {t('HOSPITALITY_REQUESTS')} {cleanerTasks.length}
                        </div>
                        <div className="ml-auto mr-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="fill-transparent stroke-current">
                                <path d="M6 9L12 15L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                </div>

                <CleanerTasksList isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} tasks={cleanerTasks} />
            </Droppable>
        </div>
    );
};
