import React from 'react';
import { useDraggable } from '@dnd-kit/core';

export const Draggable = ({ id, children, disabled = false }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id, disabled });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              zIndex: 10,
          }
        : undefined;

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes} className={`${disabled ? 'cursor-default' : ''}`}>
            {children}
        </div>
    );
};
