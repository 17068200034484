export const ReturnRemovedNodeButton = ({ callback, isDisabled }) => {
    return (
        <div className="mr-3 flex">
            <button type="button" onClick={() => callback()} className={`${!isDisabled ? '' : 'hover:stroke-blue-30'}`} disabled={!isDisabled}>
                <svg viewBox="0 0 128 128" className="w-7 h-7">
                    <path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,124a60,60,0,1,1,60-60A60.07,60.07,0,0,1,64,124Z" />
                    <path
                        strokeWidth="6"
                        d="M78.5,34h-48a2,2,0,0,0,0,4h48a21,21,0,0,1,0,42H35.33l18.6-18.58a2,2,0,0,0-2.83-2.84l-22,22-.08.1a1.2,1.2,0,0,0-.17.21l-.06.1-.13.24,0,.12c0,.09-.06.17-.08.25a2,2,0,0,0,0,.8c0,.08.05.16.08.25l0,.12.13.24.06.1a1.84,1.84,0,0,0,.17.22.73.73,0,0,1,.08.09l22,22a2,2,0,0,0,2.83,0,2,2,0,0,0,0-2.83L35.33,84H78.5a25,25,0,0,0,0-50Z"
                    />
                </svg>
            </button>
            <div data-tip="Вернуть удалённый элемент элемент" className="w-4 h-4">
                <svg viewBox="0 0 612.074 612.074" width="14" height="14" className="fill-gray">
                    <path d="M306.037,0C136.997,0,0,136.997,0,306.037s136.997,306.037,306.037,306.037s306.037-136.997,306.037-306.037 S474.261,0,306.037,0z M306.037,582.405c-152.203,0-276.368-124.165-276.368-276.368S153.019,29.669,306.037,29.669 s276.368,124.165,276.368,276.368S458.24,582.405,306.037,582.405z M318.869,454.234c0,8.827-7.195,16.021-16.021,16.021 c-8.827,0-16.021-7.195-16.021-16.021c0-8.827,7.195-16.021,16.021-16.021C311.6,438.213,318.869,445.408,318.869,454.234z  M385.328,236.315c0,28.037-16.021,53.701-45.69,71.28c-21.658,12.832-20.843,28.037-20.843,28.853c0,0,0,0.816,0,1.632v36.864 c0,8.011-6.379,14.39-14.39,14.39s-15.205-6.379-15.205-14.39v-36.048c-0.816-12.832,5.637-37.68,34.416-55.259 c14.39-8.827,31.227-24.032,31.227-46.432c0-27.221-22.4-49.696-49.696-49.696c-27.296,0-49.696,22.4-49.696,49.696 c0,8.011-6.379,14.39-14.39,14.39s-14.39-6.379-14.39-14.39c0-44.059,35.232-79.291,79.291-79.291S385.328,192.256,385.328,236.315 z" />
                </svg>
            </div>
        </div>
    );
};
