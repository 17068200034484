import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getRoomStatus, statusRoomFetchingState } from '../../../redux/slices/hospitalitySlice';

import { Loader } from '../../common/Loader';

export const RoomStatus = ({ roomName }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const statusRoomFetching = useSelector(statusRoomFetchingState);

    const [status, setStatus] = useState('');

    useEffect(() => {
        (async () => {
            if (roomName) {
                const res = await dispatch(getRoomStatus({ data: { roomName } }));

                if (res) {
                    setStatus(res);
                } else {
                    setStatus('');
                }
            } else {
                setStatus('');
            }
        })();
    }, [roomName]);

    if (statusRoomFetching) {
        return (
            <div className="flex flex-row items-center gap-1.5 text-sky-500 text-sm">
                <Loader width={14} height={14} />
                {t('ROOM_STATUS_LOADING')}
            </div>
        );
    }

    return !!status && <div className={`text-sm ${status === 'ROOM_STATUS_CLEAN' ? 'text-green-500' : 'text-red-500'}`}>{t(status)}</div>;
};
