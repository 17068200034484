import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { ErrorPopup } from '../../components/bms/mainPage/ErrorPopup';
import { Loader } from '../../components/common/Loader';

import { getBMSServersStats } from '../../redux/slices/bms/bmsSliceServers';
import { getUserInfo, userInfoFetchingState } from '../../redux/slices/bms/bmsSliceVenues';

export const BMSPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const userFetching = useSelector(userInfoFetchingState);

    useEffect(() => {
        // Получить роли
        dispatch(getUserInfo());

        // Получить статус серверов
        dispatch(getBMSServersStats());

        const interval = setInterval(() => {
            dispatch(getBMSServersStats());
        }, 60000);
        return () => clearTimeout(interval);
    }, []);

    useEffect(() => {
        if (location.pathname === '/bms') {
            navigate('/bms/venues');
        }
    }, [location]);

    if (userFetching) {
        return (
            <div className="flex h-full w-full justify-center items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <Outlet />
            <ErrorPopup />
        </>
    );
};
