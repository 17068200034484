export const InsertDocumentWithPositionButton = ({ callback }) => {
    return (
        <div className="flex">
            <button type="button" className={`hover:stroke-blue-30 stroke-stone-400 transition-colors`} onClick={() => callback('position')}>
                <svg className="w-7 h-7" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22 19.9V18.1C22 16.6 21.36 16 19.77 16H15.73C14.14 16 13.5 16.6 13.5 18.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.5 13.1V19.9C10.5 21.4 9.86 22 8.27 22H4.23C2.64 22 2 21.4 2 19.9V13.1C2 11.6 2.64 11 4.23 11H8.27C9.86 11 10.5 11.6 10.5 13.1Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.5 4.1V5.9C10.5 7.4 9.86 8 8.27 8H4.23C2.64 8 2 7.4 2 5.9V4.1C2 2.6 2.64 2 4.23 2H8.27C9.86 2 10.5 2.6 10.5 4.1Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <div data-tip="Вставить систему с сохранением позиции" className="w-4 h-4">
                <svg viewBox="0 0 612.074 612.074" width="14" height="14" className="fill-gray">
                    <path d="M306.037,0C136.997,0,0,136.997,0,306.037s136.997,306.037,306.037,306.037s306.037-136.997,306.037-306.037 S474.261,0,306.037,0z M306.037,582.405c-152.203,0-276.368-124.165-276.368-276.368S153.019,29.669,306.037,29.669 s276.368,124.165,276.368,276.368S458.24,582.405,306.037,582.405z M318.869,454.234c0,8.827-7.195,16.021-16.021,16.021 c-8.827,0-16.021-7.195-16.021-16.021c0-8.827,7.195-16.021,16.021-16.021C311.6,438.213,318.869,445.408,318.869,454.234z  M385.328,236.315c0,28.037-16.021,53.701-45.69,71.28c-21.658,12.832-20.843,28.037-20.843,28.853c0,0,0,0.816,0,1.632v36.864 c0,8.011-6.379,14.39-14.39,14.39s-15.205-6.379-15.205-14.39v-36.048c-0.816-12.832,5.637-37.68,34.416-55.259 c14.39-8.827,31.227-24.032,31.227-46.432c0-27.221-22.4-49.696-49.696-49.696c-27.296,0-49.696,22.4-49.696,49.696 c0,8.011-6.379,14.39-14.39,14.39s-14.39-6.379-14.39-14.39c0-44.059,35.232-79.291,79.291-79.291S385.328,192.256,385.328,236.315 z" />
                </svg>
            </div>
        </div>
    );
};
