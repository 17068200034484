import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSettings, object as object_state } from '../../../redux/slices/hospitalitySlice';
import { SettingsButton } from './SettingsButton';
import { AutoModeButton } from './AutoModeButton';

export const SettingsSection = ({ setSettingsPaneIsOpen }) => {
    const dispatch = useDispatch();

    const object = useSelector(object_state);

    useEffect(() => {
        if (object) {
            dispatch(getSettings());
        }
    }, [dispatch, object]);

    return (
        <div className="w-auto h-10 flex items-center border rounded-1 border-gray-70 bg-white">
            <SettingsButton setSettingsPaneIsOpen={setSettingsPaneIsOpen} />
            <AutoModeButton />
        </div>
    );
};
