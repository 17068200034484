import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { getHospitalityObjects, objects_fetching as objects_fetching_state, updateTasks, objects as objects_state } from '../../redux/slices/hospitalitySlice';
import { Loader } from '../../components/common/Loader';
import { Header } from '../../components/hospitality/header/Header';
import { SubHeader } from '../../components/hospitality/subheader/SubHeader';
import { MainSection } from '../../components/hospitality/mainSection/MainSection';
import { CleanersPane } from '../../components/hospitality/cleanersPane/CleanersPane';
import { TaskPane } from '../../components/hospitality/taskPane/TaskPane';
import { SettingsPane } from '../../components/hospitality/settingsPane/SettingsPane';
import { WorkTypesPane } from '../../components/hospitality/workTypesPane/WorkTypesPane';

export const HospitalityPage = () => {
    const dispatch = useDispatch();

    const objects_fetching = useSelector(objects_fetching_state);
    const objects = useSelector(objects_state);

    const [cleanerPaneIsOpen, setCleanerPaneIsOpen] = useState(false);
    const [taskPaneIsOpen, setTaskPaneIsOpen] = useState(false);
    const [settingsPaneIsOpen, setSettingsPaneIsOpen] = useState(false);
    const [workTypesPaneIsOpen, setWorkTypesPaneIsOpen] = useState(false);

    useEffect(() => {
        if (objects.length === 0) {
            dispatch(getHospitalityObjects());
        }
        // return () => dispatch(clear_objects());
    }, [dispatch]);

    //автообновление раз в 5 мин
    useEffect(() => {
        const intervalHandler = () => {
            dispatch(updateTasks());
        };
        let interval = setInterval(intervalHandler, 5 * 60 * 1000);

        const listener = () => {
            if (interval) {
                clearInterval(interval);
            }
            interval = setInterval(intervalHandler, 5 * 60 * 1000);
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
            clearInterval(interval);
        };
    }, [dispatch]);

    return (
        <>
            <Header setTaskPaneIsOpen={setTaskPaneIsOpen} setWorkTypesPaneIsOpen={setWorkTypesPaneIsOpen} />
            <SubHeader setCleanerPaneIsOpen={setCleanerPaneIsOpen} setSettingsPaneIsOpen={setSettingsPaneIsOpen} />
            {objects_fetching ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <MainSection />
            )}
            <CleanersPane isOpen={cleanerPaneIsOpen} setIsOpen={setCleanerPaneIsOpen} />
            <TaskPane isOpen={taskPaneIsOpen} setIsOpen={setTaskPaneIsOpen} />
            <SettingsPane isOpen={settingsPaneIsOpen} setIsOpen={setSettingsPaneIsOpen} />
            <WorkTypesPane isOpen={workTypesPaneIsOpen} setIsOpen={setWorkTypesPaneIsOpen} />

            <ReactTooltip html={true} type="dark" place="top" effect="solid" delayShow={500} className="max-w-xs" />
        </>
    );
};
