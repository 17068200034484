import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { objects_fetching as objects_fetching_state, object as object_state, objects as objects_state } from '../../../redux/slices/hospitalitySlice';

import { SubHeader } from '../../common/subheader/SubHeader';
import { SubHeaderLeft } from '../../common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../common/subheader/SubHeaderRight';
import { Title } from '../../common/subheader/Title';
import { Loader } from '../../common/Loader';
import { InformationPopup } from './InformationPopup';
import { ChangeHotelPopup } from './ChangeHotelPopup';
import { ApplyTasksButton } from './ApplyTasksButton';
import { ResetAllTasksButton } from './ResetAllTasksButton';
import { InformationButton } from './InformationButton';
import { ChangeHotelButton } from './ChangeHotelButton';
import { RefreshButton } from './RefreshButton';
import { CreateTaskPaneButton } from './CreateTaskPaneButton';
import { WorkTypesButton } from './WorkTypesButton';

export const Header = ({ setTaskPaneIsOpen, setWorkTypesPaneIsOpen }) => {
    const { t } = useTranslation();

    const objects_fetching = useSelector(objects_fetching_state);
    const object = useSelector(object_state);
    const objects = useSelector(objects_state);

    const [infoPopupIsOpen, setInfoPopupIsOpen] = useState(false);
    const [changeHotelPopupIsOpen, setChangeHotelPopupIsOpen] = useState(false);

    const informationButtonRef = useRef(null);
    const changeHotelButtonRef = useRef(null);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    {objects_fetching ? (
                        <Loader height={20} width={20} />
                    ) : (
                        <div className="flex flex-row gap-1 items-center" ref={changeHotelButtonRef}>
                            <Title>{(object && object.title) || t('HOSPITALITY_NO_OBJECT')}</Title>
                            {objects.length > 1 ? <ChangeHotelButton setPopupIsOpen={setChangeHotelPopupIsOpen} /> : null}
                        </div>
                    )}
                </SubHeaderLeft>
                <SubHeaderRight>
                    <RefreshButton />
                    <WorkTypesButton setWorkTypesPaneIsOpen={setWorkTypesPaneIsOpen} />
                    <InformationButton setPopupIsOpen={setInfoPopupIsOpen} buttonRef={informationButtonRef} />
                    <ResetAllTasksButton />
                    <ApplyTasksButton />
                    <CreateTaskPaneButton setTaskPaneIsOpen={setTaskPaneIsOpen} />
                </SubHeaderRight>
            </SubHeader>

            <InformationPopup isOpen={infoPopupIsOpen} setIsOpen={setInfoPopupIsOpen} buttonRef={informationButtonRef} />

            {objects.length > 1 ? (
                <ChangeHotelPopup isOpen={changeHotelPopupIsOpen} setIsOpen={setChangeHotelPopupIsOpen} buttonRef={changeHotelButtonRef} />
            ) : null}
        </>
    );
};
