import { useState } from 'react';

export const FeedbackButtonControl = ({ control, callback }) => {
    const [delayBg, setDelayBg] = useState(false);

    const handlerButtonClick = () => {
        const delay = control.data.values[0].delay;
        setDelayBg(true);
        callback();

        setTimeout(() => {
            setDelayBg(false);
        }, Number(delay));
    };
    return (
        <button onClick={handlerButtonClick} className="flex items-center" type="button">
            <div className={`w-4 h-4 border ${delayBg ? 'bg-white' : 'bg-slate-300'} border-black flex justify-center items-center`}>
                <div className={`w-2 h-2 border border-gray-50 ${delayBg ? 'bg-green-400' : 'bg-white'}`}></div>
            </div>
        </button>
    );
};
