import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { useTranslation } from 'react-i18next';

import { setWorkersAtWork, workers as workers_state } from '../../../redux/slices/hospitalitySlice';
import { Checkbox } from '../../common/widgets/Checkbox';
import { SlidingPaneHeader } from '../../common/slidingPane/SlidingPaneHeader';
import { SlidingPaneFooter } from '../../common/slidingPane/SlidingPaneFooter';

export const CleanersPane = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const workers = useSelector(workers_state);

    const [cleaners, setCleaners] = useState([]);
    const [searchLine, setSearchLine] = useState('');

    useEffect(() => {
        if (workers.length && isOpen) {
            setCleaners(workers.map(worker => ({ ...worker, show: true })));
        } else {
            setSearchLine('');
            setCleaners([]);
        }
    }, [workers, isOpen]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (searchLine.length) {
                setCleaners(cleaners => cleaners.map(cleaner => ({ ...cleaner, show: cleaner.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0 })));
            } else {
                setCleaners(cleaners => cleaners.map(cleaner => ({ ...cleaner, show: true })));
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    const closePaneHandler = () => {
        setIsOpen(false);
    };

    const setCleanerHandler = id => {
        setCleaners(cleaners.map(cleaner => (cleaner.id === id ? { ...cleaner, state: +!cleaner.state } : cleaner)));
    };

    const saveCleanersHandler = () => {
        const user_ids = cleaners.filter(cleaner => cleaner.state).map(cleaner => cleaner.id);

        dispatch(
            setWorkersAtWork({
                user_ids: JSON.stringify(user_ids),
            })
        );

        closePaneHandler();
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="25rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_ADD_EMPLOYEES')} />

            <div className="bg-white pt-0.5 pb-6 w-full sticky top-[60px]">
                <svg width="18" height="18" viewBox="0 0 18 18" className="fill-gray absolute left-2.5 top-3">
                    <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" />
                </svg>
                <input
                    type="text"
                    placeholder={t('HOSPITALITY_SEARCH_EMPLOYEES')}
                    className="box-border w-full h-10 text-sm rounded bg-gray-100 border-gray-10 pl-9"
                    value={searchLine}
                    onChange={e => setSearchLine(e.target.value)}
                />
            </div>

            <div className="flex flex-col gap-4 box-border px-4 py-0.5 mb-16">
                {cleaners &&
                    cleaners.map(
                        cleaner =>
                            cleaner.show && (
                                <Checkbox
                                    key={cleaner.id}
                                    name="worker"
                                    title={cleaner.name}
                                    value={cleaner.id}
                                    checked={cleaner.state}
                                    onClick={() => setCleanerHandler(cleaner.id)}
                                />
                            )
                    )}
            </div>

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={saveCleanersHandler} />
        </SlidingPane>
    );
};
