import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toastOptions } from '../../../api/functions';
import { actDoAutoWorkers, distribute_tasks_fetching as distribute_tasks_fetching_state } from '../../../redux/slices/hospitalitySlice';
import { ButtonRectangular } from '../../common/btn/ButtonRectangular';

export const DistributeButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const distribute_tasks_fetching = useSelector(distribute_tasks_fetching_state);

    const distributeHandler = async () => {
        const result = await dispatch(actDoAutoWorkers());

        if (result) {
            toast.success(t('HOSPITALITY_SAVE_OK'), toastOptions);
        } else {
            toast.error(t('HOSPITALITY_SAVE_ERROR'), toastOptions);
        }
    };

    return (
        <ButtonRectangular onClick={distributeHandler} className="w-full" loading={distribute_tasks_fetching}>
            {t('HOSPITALITY_DISTRIBUTE')}
        </ButtonRectangular>
    );
};
