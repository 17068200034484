import { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { setSettings, settingsState, sortTypesOfWorks } from '../../../redux/slices/hospitalitySlice';
import { toastOptions } from '../../../api/functions';

import { SlidingPaneHeader } from '../../common/slidingPane/SlidingPaneHeader';
import { SlidingPaneFooter } from '../../common/slidingPane/SlidingPaneFooter';
import { WorkTypeItem } from './WorkTypeItem';
import { SelectWorkType } from './SelectWorkType';

export const WorkTypesPane = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const settings = useSelector(settingsState);

    const [workTypes, setWorkTypes] = useState([]);

    useEffect(() => {
        if (settings && settings.hasOwnProperty('pms_task_types') && isOpen) {
            setWorkTypes(settings.pms_task_types);
        }
    }, [settings, isOpen]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const closePaneHandler = () => {
        setIsOpen(false);
    };

    const updateHandler = () => {
        const usedWorkTypes = workTypes.filter(type => type.used);
        let findIndexName = -1;
        let findIndexColor = -1;

        for (let i = 0; i < usedWorkTypes.length; i++) {
            findIndexName = usedWorkTypes.findIndex(item => item.use_title === usedWorkTypes[i].use_title && item.id !== usedWorkTypes[i].id);
            if (findIndexName >= 0) {
                break;
            }
        }

        for (let i = 0; i < usedWorkTypes.length; i++) {
            findIndexColor = usedWorkTypes.findIndex(item => item.color === usedWorkTypes[i].color && item.id !== usedWorkTypes[i].id);
            if (findIndexColor >= 0) {
                break;
            }
        }

        if (usedWorkTypes.find(type => type.use_title.length < 5)) {
            toast.error(t('HOSPITALITY_TYPE_OF_WORK_MIN_CHARACTERS'), toastOptions);
        } else if (usedWorkTypes.find(type => type.use_title.length > 255)) {
            toast.error(t('HOSPITALITY_TYPE_OF_WORK_MAX_CHARACTERS'), toastOptions);
        } else if (findIndexName >= 0) {
            toast.error(t('HOSPITALITY_TYPE_OF_WORK_UNIQUE'), toastOptions);
        } else if (findIndexColor >= 0) {
            toast.error(t('HOSPITALITY_TYPE_OF_WORK_COLOR_UNIQUE'), toastOptions);
        } else {
            const arr = workTypes.map(el => el.id);

            dispatch(
                sortTypesOfWorks({
                    data: JSON.stringify(arr),
                })
            );
            dispatch(setSettings({ data: { options: JSON.stringify({ pms_task_types: workTypes }) } }));
            closePaneHandler();
            toast.info(t('HOSPITALITY_UPDATED_SUCCESSFULLY'), toastOptions);
        }
    };

    const handleDragEnd = event => {
        if (event.over && event.active.id !== event.over?.id) {
            const activeObj = workTypes.find(type => type.id === event.active.id);
            const overObj = workTypes.find(type => type.id === event.over.id);

            const activeIndex = workTypes.findIndex(({ id }) => id === activeObj.id);
            const overIndex = workTypes.findIndex(({ id }) => id === overObj.id);

            setWorkTypes(arrayMove(workTypes, activeIndex, overIndex));
        }
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="39rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_WORK_TYPES')} />

            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={workTypes} strategy={verticalListSortingStrategy}>
                    <div className="flex flex-col gap-6 mb-6 mt-1 overflow-hidden">
                        {workTypes && workTypes.filter(type => type.used).map(type => <WorkTypeItem key={type.id} item={type} setWorkTypes={setWorkTypes} />)}
                    </div>
                </SortableContext>
            </DndContext>

            <SelectWorkType setWorkTypes={setWorkTypes} workTypes={workTypes} />

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={updateHandler} />
        </SlidingPane>
    );
};
