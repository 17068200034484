import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { settingsState } from '../../../redux/slices/hospitalitySlice';
import { MultiSelect } from '../../common/widgets/MultiSelect';

export const ClearTypesFilter = ({ setFilter, value }) => {
    const { t } = useTranslation();

    const settings = useSelector(settingsState);

    const [clearTypes, setClearTypes] = useState([]);

    useEffect(() => {
        if (settings && settings.hasOwnProperty('pms_task_types')) {
            setClearTypes(
                settings.pms_task_types
                    .filter(type => type.used)
                    .map(type => ({
                        value: type.id,
                        label: type.use_title,
                    }))
            );
        }
    }, [settings]);

    return (
        <MultiSelect options={clearTypes} placeholder={t('HOSPITALITY_WORK_TYPES')} callback={val => setFilter({ type: 'clearTypes', val })} value={value} />
    );
};
